<script>
import { defineComponent, computed, ref, onMounted, onBeforeUnmount } from "vue"
import MouseOut from '@kit/utils/MouseOut'
import { actionSetOA2T, actionOA2T, animate } from '@kit/utils/Animate'

export default defineComponent({
  props: ["type","to", "target", "outerColor", "innerColor"],
  
  name: "AnimationLink",
  setup(props) {

    let m = null
    const root = ref(null)
    const lineOuter = ref(null)
    const lineInner = ref(null)

    const goIn = {
      translateX:"0%",
      easing:"linear",
      duration:100
    }
    const goOut = {
      translateX:"100%",
      easing:"easeOutQuad",
      duration:100
    }

    const rolloverActions = actionSetOA2T([
      {
        name:"mouseOver",
        element(container) {
          return container
        },
        async action(container, outer, inner) {
          goIn.targets = inner
          inner.style.transform = "translateX(-101%)"
          await animate(goIn)
        }
      },
      {
        name:"mouseOut",
        element(container) {
          return container
        },
        async action(container, outer, inner) {
          goOut.targets = inner
          await animate(goOut)
          inner.style.transform = "translateX(-101%)"
        }
      },
    ])

    const goRollover = () => {
      actionOA2T(rolloverActions, "mouseOver", [root.value, lineOuter.value, lineInner.value])
    }
    const goRollout = () => {
      actionOA2T(rolloverActions, "mouseOut", [root.value, lineOuter.value, lineInner.value])
    }

    onMounted(() => {

      if(!m) {
        m = new MouseOut()
      }

      const domNode = props.type == 'router-link' ? root.value.$el : root.value

      m.register(domNode, 
        function({ currentTarget }) {
          goRollover()
        },
        function({ currentTarget }) {
          goRollout()
        }
      )  

    })

    onBeforeUnmount(() => {
      if(m) {
        m.destroy()
        m = null
      }
    })

    return { root, lineOuter, lineInner }
  }
})
</script>

<style scoped>
  .line-outer {
    height:2px;
    width:100%;
    margin-top:4px;
    position: relative;
    overflow:hidden;
  }
  .line-inner {
    height:2px;
    width:100%;
    left:0px;
    top:0px;
    transform: translateX(-101%)
  }

</style>

<template>
  <router-link ref="root" v-if="type == 'router-link'" :to="to">
    <div><slot></slot></div>
    <div class="line-outer" ref="lineOuter" :style="`background-color:${outerColor}`">
      <div class="line-inner" ref="lineInner" :style="`background-color:${innerColor}`"></div>
    </div>
  </router-link>
  <a v-else-if="type == 'a'" ref="root" :href="to" :target="target">
    <div><slot></slot></div>
    <div class="line-outer" ref="lineOuter" :style="`background-color:${outerColor}`">
      <div class="line-inner" ref="lineInner" :style="`background-color:${innerColor}`"></div>
    </div>
  </a>
  <div v-else ref="root" :href="to" :target="target">
    <div><slot></slot></div>
    <div class="line-outer" ref="lineOuter" :style="`background-color:${outerColor}`">
      <div class="line-inner" ref="lineInner" :style="`background-color:${innerColor}`"></div>
    </div>
  </div>
</template>