import { sleep } from '@kit/utils/Sleep'
import { getBoundingClientRect } from '@kit/utils/BoundingClientRect'

/////////////////////////////////////////////////////////////////
//
//  A utility to help smooth out mouseover/mouseout problems,
//  specifically mouseout problems. More details below
//
/////////////////////////////////////////////////////////////////

const MouseOutItem = "_99483"
const MouseOutOutHandler = "_0694u3"
const MouseOutOverHandler = "_mfpe0"
const MouseOutRollToggle = "_247362"
const MouseOutChained = "_102948"
const MouseOutRegistered = "_683849"

// https://stackoverflow.com/questions/923299/how-can-i-detect-when-the-mouse-leaves-the-window
// https://plnkr.co/edit/eiVixZwxlxFG9dyx?p=preview&preview
// https://javascript.info/bubbling-and-capturing
// https://codepen.io/CodeOwl/pen/abjPLYM

class MouseOut {

  static firstInst = null 
  static lastInst = null
  static numInst = 0
  static currentMouseX = 0
  static currentMouseY = 0
  static intervalDuration = 250

    // Note that the mouse coords are clientX/Y, 
    // which is in the same coordinate system as the getBoundingClientRect

    constructor() {

      if(typeof window != "undefined") {
        this._prev = null 
        this._next = null
        this._scrolledToTop = true

        this._firstItem = null
        this._lastItem = null
        this._numItems = 0
        
        this._runItemInterval = null

        this._registered = null

        //an alias so that we can remove the handler.
        this._elementMouseOut = (e) => {
          this.elementMouseOut(e)
        }
        this._elementMouseOver = (e) => {
          this.elementMouseOver(e)
        }

        //Add to the instance chain
        MouseOut.addInstance(this)
      }
  
    }

    //Destroy. Remove the instance from the chain, 
    //clear the item chain from this instance, 
    //and unregister all of the elements
    destroy() {
      if(typeof window != "undefined") {
        MouseOut.removeInst(this)
        this.clearItemChain()
        this.unregister()
      }
    }

    //On mouse move, just set the mouse xy
    static _mouseMove = (e) => {
      MouseOut.currentMouseX = e.clientX 
      MouseOut.currentMouseY = e.clientY
    }

    //On mouse leave we're going to tellall the instances 
    //that the mouse has left the window
    static _mouseLeave = (_e) => {
      let inst = MouseOut.firstInst
      while(inst) {
        const next = inst._next
        inst.mouseLeave()
        inst = next
      }
    }


    //The first of these instances to appear will add these listeners to the window and document.
    //The last to destroy will remove them.
    static addListeners() {
      window.addEventListener('mousemove', MouseOut._mouseMove)
      document.addEventListener('mouseleave', MouseOut._mouseLeave)
    }
    static removeListeners() {
      window.removeEventListener('mousemove', MouseOut._mouseMove)
      document.removeEventListener('mouseleave', MouseOut._mouseLeave)
    }

    //Add an instance to the instance chain. The first instance to appear will
    //trigger the shared listeners.
    static addInstance(inst) {

        if(MouseOut.numInst == 0) {
          MouseOut.firstInst = inst
          MouseOut.lastInst = inst
          MouseOut.addListeners()
        } else {
  
          if(MouseOut.lastInst) {
            MouseOut.lastInst._next = inst
          }
          inst._prev = MouseOut.lastInst
          MouseOut.lastInst = inst
  
        }
        MouseOut.numInst++
    }
  
    //remove the instance from the instance chain. 
    //the last instance to go will remove the shared window and document listeners
    static removeInst(inst) {

        if(inst._prev) {
          inst._prev._next = inst._next
        } else {
          MouseOut.firstInst = inst._next
        }
        if(inst._next) {
          inst._next._prev = inst._prev
        } else {
          MouseOut.lastInst = inst._prev
        }
        MouseOut.numInst--
  
        inst._prev = null 
        inst._next = null

        if(!MouseOut.firstInst && !MouseOut.lastInst) {
          MouseOut.removeListeners()
        }
    }


    //The handler for the mouse over event.
    async elementMouseOver({ currentTarget, target }) {

      //There will generally be lots of little elements within the big 
      //area whose mouse-out we're actually trying to get. We only want 
      //to do one mouseover, and one mouseout, not a mouseover for every 
      //tiny little thing inside the area.
      if(!currentTarget[MouseOutRollToggle]) {
        this.addItemToChain(MouseOut.newItem(currentTarget))
        currentTarget[MouseOutRollToggle] = true
        currentTarget[MouseOutOverHandler]({currentTarget, target})
      }
    }

    // So we got a mouse-out event, eh? But see, every single tiny little damn 
    // thing triggers a mouseout event. So is this for real? So what we do 
    // is was check the bounds of both the nav option as well as the drop-down
    // selections to see if the user ACTUALLY rolled out.
    //
    // You're going to have questions about this. You're going to say- now hold on 
    // Alex, why don't we just do the same trick that we did with the elementMouseOver?
    // Go here and play with this
    // https://codepen.io/CodeOwl/pen/abjPLYM
    //
    // Notice that when you roll off the big green square, it doesn't distinguish between 
    // actually rolling off vs rolling onto the nested blue square. The outputs are the same.
    // We can't tell what is happening based on the information from the event period end of story.
    // We only have a couple of options. We could do something where we, like, WAIT for a sec to see 
    // if a mouseover event is getting captured right after. Eeesh that seems like kind of a leap of 
    // faith and I could see edge-cases happening where the browser decides to switch the order on you.
    // What I've decided to is just look at the actual geometry of the dom element and see if we have a 
    // "true" rollout event. Note that we're simplifying things for just rectangles. 
    //
    // A little more context. 
    // https://plnkr.co/edit/eiVixZwxlxFG9dyx?p=preview&preview
    // https://javascript.info/bubbling-and-capturing
    //
    //
    async elementMouseOut({ currentTarget, target }) {

      // if(!currentTarget[MouseOutChained]) {
      //   return 
      // }
      // await sleep(50)
      // const x = MouseOut.currentMouseX
      // const y = MouseOut.currentMouseY

      // const { top:t1, bottom:b1, right:r1, left:l1 } = await getBoundingClientRect(currentTarget, true)

      // const isOutsideOfRegion = y < t1 || y > b1 || x < l1 || x > r1 

      // if(isOutsideOfRegion && currentTarget[MouseOutRollToggle]) {
      //   const item = currentTarget[MouseOutItem]
      //   this.mouseLeaveActuallyHappened(item, currentTarget, target)
      // }
    }
    
    //The mouse left the screen/window/viewport so we're going to tell everything that 
    //an actual rolloff happened.
    mouseLeave(_e) {
      this.runItemChain(true)
    }

    //Unregister all of the dom-elements that we previously registered.
    unregister() {
      if(!this._registered) {
        return
      }

      const r = this._registered
      for(let i=0; i<r.length; i++) {
        const domEl = r[i]
        this.unregisterOne(domEl)
      }
      this._registered = null
    }

    //Register all the dom elements pointed to by a query-selector
    //qSelector cam be either a css query selector, or a dom-node itself.
    register(qSelector, onMouseOver, onMouseOut) {
      if(typeof window != "undefined") {
        if(this._registered) {
          this.unregister()
        }
        const domEls = typeof qSelector == "string" ? document.querySelectorAll(qSelector) : [qSelector]
        this._registered = domEls
        for(let i=0; i<domEls.length; i++) {
          this.registerOne(domEls[i], onMouseOver, onMouseOut)
        }
      }
    }

    //register or unregister a single dom element
    async registerOne(domEl, onMouseOver, onMouseOut) {
      if(domEl[MouseOutRegistered]) {
        return
      }
      domEl[MouseOutRegistered] = true
      domEl[MouseOutOutHandler] = onMouseOut
      domEl[MouseOutOverHandler] = onMouseOver
      domEl.addEventListener("mouseover", this._elementMouseOver)
      domEl.addEventListener("mouseout", this._elementMouseOut)

    }
    unregisterOne(domEl) {
      if(!domEl[MouseOutRegistered]) {
        return
      }
      domEl[MouseOutRegistered] = false
      domEl[MouseOutOutHandler] = null
      domEl[MouseOutOverHandler] = null
      domEl.removeEventListener("mouseover", this._elementMouseOver)
      domEl.removeEventListener("mouseout", this._elementMouseOut)
    }

    //Make a new item for this hover linked-list
    static newItem(domElement) {
      const item = { prev:null, next:null, el:domElement }
      domElement[MouseOutItem] = item
      return item
    }

    //The mouse actually left the dom-element based on either geometric information 
    //or on the fact that the mouse left the stage. So call the function and remove 
    //it from the chain.
    mouseLeaveActuallyHappened(item, _currentTarget, _target) {
      const currentTarget = _currentTarget || item.el 
      const target = _currentTarget || item.el
      //TypeError: Cannot set properties of null (setting '_247362')
      item.el[MouseOutRollToggle] = false  // item.el[MouseOutRollToggle] = false
      item.el[MouseOutOutHandler]({ currentTarget, target })
      this.removeItemFromChain(item)
    }

    //run the a single hover-item
    async runItem(item, mouseleave) {

      //if mouseleave, then that means that the user just rolled off the window, so everything is going 
      //to perform the rolled-off transition
      if(mouseleave) {
        this.mouseLeaveActuallyHappened(item)
      } 
      
      //else, we're going to check for the boundaries of the mouse within the actual ui elements.
      //We can get away with this because the top nav is a fixed element. getBoundingClientRect 
      //causes a reflow, but that's tightly bounded because we're right in a fixed element so it 
      //won't hit the CPU too hard.
      //https://medium.com/geekculture/the-browser-reflow-whereabouts-c3d963eabe4a
      else {

      const x = MouseOut.currentMouseX
      const y = MouseOut.currentMouseY

      const { top:t1, bottom:b1, right:r1, left:l1 } = await getBoundingClientRect(item.el, true)

      const isOutsideOfRegion = y < t1 || y > b1 || x < l1 || x > r1 

        if(isOutsideOfRegion) {
          this.mouseLeaveActuallyHappened(item)
        }
      }
    }

    //run the whole hover-item chain
    runItemChain(mouseleave) {
      let item = this._firstItem
      while(item) {
        const next = item.next
        this.runItem(item, mouseleave)
        item = next
      }
    }
    
    //clear the item chain
    clearItemChain() {
      let item = this._firstItem
      while(item) {
        const next = item.next
        this.removeItemFromChain(item)
        item = next
      }   
    }

    //start the poll
    startItemInterval() {
      const ctx = this
      this._runItemInterval = setInterval(() => {
        ctx.runItemChain(false)
      },MouseOut.intervalDuration)
    }

    //end the poll
    endItemInterval() {
      clearInterval(this._runItemInterval)
      this._runItemInterval = null
    }

    //Add the hover-item to the chain
    addItemToChain(item) {

      if(item.el[MouseOutChained]) {
        return
      }
      item.el[MouseOutChained] = true

      if(this._numItems == 0) {
        this._firstItem = item
        this._lastItem = item
        this.startItemInterval()
      } else {

        if(this._lastItem) {
          this._lastItem.next = item
        }
        item.prev = this._lastItem
        this._lastItem = item

      }
      this._numItems++
    }

    //remove the hover-item from the chain
    removeItemFromChain(item) {

      if(!item.el[MouseOutChained]) {
        return
      }
      item.el[MouseOutChained] = false

      if(item.prev) {
        item.prev.next = item.next
      } else {
        this._firstItem = item.next
      }
      if(item.next) {
        item.next.prev = item.prev
      } else {
        this._lastItem = item.prev
      }
      this._numItems--

      item.prev = null 
      item.next = null
      item.el = null

      if(!this._firstItem && !this._lastItem) {
        this.endItemInterval()
      }
    }

  }

export default MouseOut
