<script>
import { defineComponent, computed } from "vue";
import SidebarRouteLink from "@kit/components/SidebarRouteLink.vue"
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import SideNavDrawerBase from "@kit/components/SideNavDrawerBase.vue"
import { searchWidgetMobile, sideDrawer  } from "@project/themes"
import IconButton from '@kit/components/IconButton.vue'
import { useStore } from "vuex"
import { getCurrentYear } from '@kit/utils/Formats'

export default defineComponent({
  name: "SizeNavDrawer",
  setup() {
    const store = useStore()

    const userAccount = computed(() => {
      return store.state.account
    })

    return { searchWidgetMobile, sideDrawer, userAccount,  getCurrentYear };

  },
  components: {
    SideNavDrawerBase,
    SidebarRouteLink,
    NavMainSearch,
    IconButton
  },
});
</script>

<style>

.rviha-side-nav-drawer {
  background: rgb(255,38,0);
  background: linear-gradient(90deg, rgba(255,38,0,1) 0%, rgba(244,97,86,1) 100%);
  opacity: 1;
}

.ics-sidenav-item > a {
  color:white;
  font-size:1.5rem !important;
}
.ics-sidenav-copyright {
  color:white;
  font-size:1.2rem;
}

</style>

<template>
  <SideNavDrawerBase :theme="sideDrawer">
    <template #nav-items="{ active }">

      

      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/">Home</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/about">About</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/announcements">Announcements</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/projects">Projects</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/documents">Documents</SidebarRouteLink>
      </div>
      <div class="sb ics-sidenav-item">
        <SidebarRouteLink to="/contact-us">Contact Us</SidebarRouteLink>
      </div>

      <!-- <NavMainSearch :theme="searchWidgetMobile" :active="active" />
       -->
      <div class="sb sb-text ics-sidenav-copyright">
        © {{ getCurrentYear() }} RVIHA. All rights reserved
      </div>

    </template>
  </SideNavDrawerBase>
</template>
