//import { home } from '@project/api/home/v1'
//import { section } from '@project/api/sections/v1'
import { contactUs } from '@project/api/contact-us/v1'
//import { aboutUs } from '@project/api/about-us/v1'
//import { services } from '@project/api/services/v1'

export default {
  async contactUs() {
    return await contactUs()
  }
}

