export const routes = [
  {
    name: 'root',
    path: '/',
    component: () => import(/* webpackChunkName: "root" */'@pages/Home.vue')
  },
  {
    name:"about",
    path: '/about/:section?',
    component: () => import(/* webpackChunkName: "about" */'@pages/About.vue'),
    meta: {
      paramsMappedToMultipleSections:true,
      sitemap: {
        slugs: () => {
          return ['services']
        }
      }
    },
  },
  {
    name:"projects",
    path: '/projects',
    component: () => import(/* webpackChunkName: "projects" */'@pages/Projects.vue'),
  },
  {
    name:"24-additional-living-units",
    path: '/projects/24-additional-living-units',
    component: () => import(/* webpackChunkName: "24-additional-living-units" */'@pages/Project24LivingUnits.vue')
  },
  {
    name:"main-building-expansion",
    path: '/projects/main-building-expansion',
    component: () => import(/* webpackChunkName: "main-building-expansion" */'@pages/ProjectMainBuildingExpansion.vue')
  },
  {
    name:"announcements",
    path: '/announcements',
    component: () => import(/* webpackChunkName: "announcements" */'@pages/Announcements.vue')
  },
  {
    name:"documents",
    path: '/documents/:section?',
    component: () => import(/* webpackChunkName: "documents" */'@pages/Documents.vue'),
    meta: {
      paramsMappedToMultipleSections:true,
      sitemap: {
        slugs: () => {
          return ['policies','forms','reports','resources']
        }
      }
    },
  },
  {
    name:"contact-us",
    path: '/contact-us',
    component: () => import(/* webpackChunkName: "contact-us" */'@pages/Contact.vue')
  }

]