<script>
import { defineComponent, inject } from "vue";
import NavMainSearch from "@kit/components/NavMainSearch.vue";
import { searchWidgetMain, navbar } from "@project/themes";
import NavBarBase from "@kit/components/NavBarBase.vue";
import '@kit/assets/css/superbox-core-x.css'

export default defineComponent({
  name: "NavBar",
  setup() {
    const themer = inject("themer")

    //ALEX-RESKIN
    const navbarTheme = themer(navbar)

    //return { searchWidgetMain, navbar }; //ALEX-RESKIN
    return { searchWidgetMain, navbarTheme }
  },
  components: {
    NavMainSearch,
    NavBarBase,
  },
});
</script>

<style>
.rviha-nav-outer {
    width: auto !important;
    background-color: #fff !important;
    position:absolute !important;
    left:80px;
    right:80px;
}

.rviha-nav-outer-mobile {
    width: auto !important;
    background-color: #fff !important;
    position:absolute !important;
    left:0px;
    right:0px;
}

.rviha-nav-item-contact {
  background-color:#ff2600;
  padding-left:30px;
  padding-right:30px;
  transition: 0.3s background-color ease;
}
.rviha-nav-item-contact:hover {
  background-color: #ee2d0b;
}

.rviha-nav-item-contact > a {
  color: white;
  font-family: Dosis, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
}


.rviha-nav-item,
.rviha-nav-item > a {
  color: #414147;
  font-family: Dosis, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
}
.rviha-nav-item > a {
  padding:5px;
  background:white;
  z-index:2;
}
.rviha-nav-item > span {
  padding:5px;
  background:white;
  z-index:2;
}

.rviha-nav-item > i {
  position:absolute;
  top:0px;
  left:50%;
  border-left:1px solid #ee2d0b;
  height:100%;
  transform:scaleY(0%);
  transform-origin: top;
  z-index:1;
}

.rviha-nav-item:hover,
.rviha-nav-item.nav-none:hover > a {
  transition: color 0.3s linear;
}
.rviha-nav-item:hover > i {
  transition: transform 0.3s linear;
}
.rviha-nav-item:hover,
.rviha-nav-item.nav-none:hover > a {
  color: #ee2d0b;
}
.rviha-nav-item:hover > i {
  transform: scaleY(100%);
}

.rviha-logo-wrapper {
  width: 150px;
  height:120px;
  padding: 15px;
  background-color:#ee2d0b;
}

.rviha-nav-item {
  padding: 20px;
  cursor: pointer;
}

.rviha-nav-item:hover {
  background: #ffffff;
}

.rviha-nav-subitems {
  background-color:white;
  padding:10px;
}

.rviha-nav-subitems > a {

  color: #414147;
  font-family: Dosis, sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
  background-color: white;
  padding: 10px 20px 10px 20px;
  position: relative;

  /* font-family: Oswald, sans-serif;
  position: relative;
  background-color: var(--dark-text);
  color: #e2e2e2;
  padding: 10px 20px 10px 20px;
  overflow: hidden;
  transition: background-color 0.3s linear; */
}
.rviha-nav-subitems > a:hover {
  background-color: #EEEEEE;
  color:#ee2d0b;
}
.rviha-nav-subitems > a:visited {
  font-weight: normal;
}

/* Search styles */

.rviha-nav-main-search-searchoption {
  z-index:1;
  padding-left:0px !important; 
  padding-right:0px !important; 
  width:30px !important;
}

.rviha-search-modal-window {
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  min-height: 335px;
  padding-top: 107px;
  padding-bottom: 50px;
  background-color: rgba(6, 42, 70, 0.92);
  background-image: linear-gradient(257deg, rgba(19, 95, 129, 0.68), rgba(6, 42, 70, 0.73));
  opacity: 1;
}

input.nav-mobile-search-input:focus, input.nav-main-search-input:focus, input.nav-main-search-nonmodal-input:focus  {
  background-color:#d4e3fa !important;
}

input.nav-mobile-search-input, input.nav-main-search-input, input.nav-main-search-nonmodal-input {
  border-style: none none solid;
  background-color: #f8f8f8;
  font-family: Oswald, sans-serif;
  color: #000;
  font-size: 18px;
  font-weight: 300;
  padding:10px !important;
}
input.nav-main-search-nonmodal-input {
  border: 1px solid #AAAAAA;
}
@media (max-width : 960px) {
  input.nav-mobile-search-input {
    font-size: 25px;
  }
  .rviha-logo-wrapper {
    height:90px;
    padding:0px;
  }

}

.rviha-search-results-outer {
  margin-bottom:20px;
}

@media (max-width : 960px) {
  .rviha-search-results-outer {
    margin-top:80px;
  }
}

.nav-main-search-ui-container {
  background-color:white;
  border-radius:5px;
}
.nav-mobile-search-ui-container {
  background-color:white;
  border-radius:5px;
}

/*modal styles*/
.rviha-search-modal-search-button {
  border: 2px solid var(--light-blue);
  font-size: 16px;
  width: 200px !important;
}
.rviha-search-results-prevnext-wrapper {
  padding:0px !important;
}

/*nonmodal widget*/
.rviha-search-modal-search-nonmodal-button {
  width: 50px !important;
}
.rviha-search-nonmodal-headline {
  font-family: Oswald, sans-serif;
  font-weight:400;
  color: var(--dark-text);
}

/*styles for the mobile side-nav drawer ui*/
.rviha-mobile-search-button {
  width: 50% !important;
}
.rviha-mobile-search-button svg {
  transform: rotate(45deg);
}
.rviha-mobile-search-headline {
  font-family: Oswald, sans-serif;
  font-size: 36px;
  line-height: 36px;
  font-weight: 400;
  color:white; 
}
.rviha-mobile-search-wrapper {
  padding-top:20px;
  padding-right:20px;
}
.rviha-mobile-search-completion-wrapper, .rviha-main-search-completion-wrapper  {
  color:black;
  font-family: Oswald, sans-serif;
  font-size: 20px;
}
.rviha-mobile-completion-result {
  padding:7px;
}
.rviha-main-completion-result {
  padding:7px;
  font-size: 18px;
}
.rviha-mobile-completion-result:hover, .rviha-main-completion-result:hover  {
  background-color:#EEEEEE;
}




</style>

<template>
  <NavBarBase :theme="navbarTheme">
    <template v-slot:nav-items>
      <div class="sb sb-h rviha-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/">home</router-link><i></i>
      </div>
      <div class="sb sb-h rviha-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/about">about</router-link><i></i>
      </div>
      <div class="sb sb-h rviha-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/projects">projects</router-link><i></i>
      </div>
      <div class="sb sb-h rviha-nav-item nav-none sb-align-cv navbar-item">
        <router-link class="sb" to="/announcements">announcements</router-link><i></i>
      </div>
      <div class="sb sb-h rviha-nav-item nav-dropdown sb-align-cv navbar-item navbar-dropdown">
        <span>documents</span><i></i>
        <div class="sb sb-v navbar-subitems rviha-nav-subitems rviha-nav-item-text">
          <router-link to="/documents/policies" style="white-space: nowrap">
            policies
          </router-link>
          <router-link to="/documents/forms" style="white-space: nowrap">
            forms
          </router-link>
          <router-link to="/documents/reports" style="white-space: nowrap">
            reports
          </router-link>
          <router-link to="/documents/resources" style="white-space: nowrap">
            resources
          </router-link>
        </div>
      </div>
      <div class="sb sb-h rviha-nav-item-contact sb-align-cv navbar-item">
        <router-link class="sb" to="/contact-us">contact us</router-link><i></i>
      </div>
      <!-- <NavMainSearch :theme="searchWidgetMain" /> -->
    </template>
  </NavBarBase>
</template>
