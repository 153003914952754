
//import { pathname } from '@kit/utils/Formats'


export const navbar = {

  //optional. if it will shrink on scroll
  scrollShrink: false,

  //mergeable classes for the hamburger button
  //hamburgerButtonClasses: "some class",
  outerContainerClassesDesktop: { override: "navbar-fixed-ui-container sb sb-h sb-greedy-1 top-nav visible-gtm sb sb-abox rviha-nav-outer" },
  outerContainerClassesMobile: { override: "navbar-fixed-ui-container sb sb-h sb-content-space-between top-nav invisible-gtm top-nav invisible-gtm sb-abox rviha-nav-outer-mobile" },
  innerContainerClassesDesktop: "sb-greedy",

  logoWrapperClasses:{override:"sb sb-h sb-align-c sb-explicit rviha-logo-wrapper"} ,

  //the logo and aria text
  logoSrc: "/cms-static-assets/2023/07/Logo-edited-v1-01.png",
  logoAriaAlt:"7-tribes navigation logo",

  //required
  height: "90px",
  shrinkHeight: "90px",

  // padding: "15px",
  // shrinkPadding: "5px",
  skipLogoPadding: true,

  //optional. The top gap
  topGap: "40px"
    
}

export const sideDrawer = {
  topNavHeight:"90px",
  outerClasses:"rviha-side-nav-drawer",
  innerClasses:"sb-content-space-evenly"
}