
<script>
import { defineComponent } from 'vue'
import { getCurrentYear } from '@kit/utils/Formats'
import AnimationLink from '@project/components/AnimationLink.vue'

export default defineComponent({
  name: "Footer",
  setup() {
    return { 
      getCurrentYear
    }
  },
  components: {
    AnimationLink
  }
});
</script>

<template>

<div class="section color wf-section">
    <div class="container w-container">
      <div>
        <div class="top-title-wrapper center vertical">
          <h1 class="top-title white">LET&#x27;S MAKE SOMETHING GREAT TOGETHER <strong>
              <br />
            </strong>
          </h1>
          <p class="paragraph-white biger">Get in touch with us to discuss your how we can help you live a better life. <br />
          </p>
          <div class="top-margin half">
            <AnimationLink 
              class="navlink for-button blue white w-inline-block" 
              type="router-link" 
              outerColor="rgba(255,255,255,0.5)"
              innerColor="#ffffff"
              to="/contact-us">Get Started
            </AnimationLink>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container w-container">
      <div class="w-row">
        <div class="w-col w-col-4">
          <div class="footer-column">
            <div class="with-line">
              <h1 class="top-title smaller-white in-footer">About Us <strong>
                  <br />
                </strong>
              </h1>
              <div class="line"></div>
            </div>

            <p class="paragraph-white _100">The RVIHA is the Tribally Designated Housing Entity (TDHE) and the designated recipient of the annual Native American Housing Assistance and Self Determination Act (NAHASDA) Indian Housing Block Grant (IHBG). <a href="http://structure-template.webflow.io/contact">
                <strong>
                  <br />
                </strong>
              </a>
            </p>
            <AnimationLink 
              class="navlink for-button blue white for-footer w-inline-block" 
              type="router-link" 
              outerColor=""
              innerColor="#ffffff"
              to="/contact-us">Find on map
            </AnimationLink>
          </div>
        </div>
        <div class="w-col w-col-4">
          <div>
            <div class="footer-column">
              <div class="with-line">
                <h1 class="top-title smaller-white in-footer">Services <strong>
                    <br />
                  </strong>
                </h1>
                <div class="line"></div>
              </div>
              <div>
                <AnimationLink 
                  class="navlink for-button blue white for-footer w-inline-block" 
                  type="router-link" 
                  outerColor=""
                  innerColor="#ffffff"
                  to="/about">About Us
                </AnimationLink>
              </div>
              <div>
                <AnimationLink 
                  class="navlink for-button blue white for-footer w-inline-block" 
                  type="router-link" 
                  outerColor=""
                  innerColor="#ffffff"
                  to="/projects">Our Projects
                </AnimationLink>
              </div>
              <div>
                <AnimationLink 
                  class="navlink for-button blue white for-footer w-inline-block" 
                  type="router-link" 
                  outerColor=""
                  innerColor="#ffffff"
                  to="/announcements">Announcements
                </AnimationLink>
              </div>
              <div>
                <AnimationLink 
                  class="navlink for-button blue white for-footer w-inline-block" 
                  type="router-link" 
                  outerColor=""
                  innerColor="#ffffff"
                  to="/documents">Documents
                </AnimationLink>
              </div>
              <div>
                <AnimationLink 
                  class="navlink for-button blue white for-footer w-inline-block" 
                  type="router-link" 
                  outerColor=""
                  innerColor="#ffffff"
                  to="/contact-us">Contact Us
                </AnimationLink>
              </div>
            </div>
          </div>
        </div>
        <div class="w-col w-col-4">
          <div>
            <div class="footer-column">
              <div class="with-line">
                <h1 class="top-title smaller-white in-footer">contact us <strong>
                    <br />
                  </strong>
                </h1>
                <div class="line"></div>
              </div>
              <div>
                <div class="info-child horizontal no-center">
                  <img src="@images/5e2dee3586f41728e5260a57_phone.svg" alt="" class="info-image left" />
                  <div>
                    <h1 class="phone-text">Phone Number <br />
                    </h1>
                    <p class="paragraph-white">707-983-6188 <br />
                    </p>
                  </div>
                </div>
                <div class="info-child horizontal no-center">
                  <img src="@images/5e2dee3586f417df9c260a59_clock.svg" alt="" class="info-image left" />
                  <div>
                    <h1 class="phone-text">Opening Times <br />
                    </h1>
                    <p class="paragraph-white">Mon - Fri 8:00 - 5:00 <br />Sat - Sun Closed <br />
                    </p>
                  </div>
                </div>
                <div class="info-child horizontal no-center">
                  <img src="@images/5e2dee3586f417403a260a58_mail-1.svg" alt="" class="info-image left" />
                  <div>
                    <h1 class="phone-text">Contact <br />
                    </h1>
                    <p class="paragraph-white">nmccoy@rviha.org <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright-margin">
        <div class="row-flex verical reverse w-row">
          <div class="w-col w-col-6 w-col-stack">
            <a href="index.html" aria-current="page" class="brand normal w-nav-brand w--current">
              <img src="/cms-static-assets/2023/07/Logo-edited-v1-01.png" width="90" alt="" />
            </a>
          </div>
          <div class="w-col w-col-6 w-col-stack">
            <div class="left-copyright-flex right">
              <p class="copyright">© {{ getCurrentYear() }} Round Valley Indian Housing Authority | Covelo, CA 95428</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>