import { ButtonAlignCenter, ButtonFlashRadial, ButtonIconPositionShiftLeft, ButtonIconActiveHeight, ButtonIconPassiveHeight } from '@kit/components/IconButton.vue'


  export const greenButton = {
    foreground: {
      active:   "#FFFFFF",
      inactive: "#FFFFFF",
      flash:    "#FFFFFF",
      loading:  "#FFFFFF" 
    },
    background: {
      active:       "#ff2600",
      inactive:     "#CCCCCC",
      flash:        "#ff5500", 
      loading:      "#CCCCCC",
      hover:        "#ed0226",  
      borderRadius: "10px",   
      padding:      "10px"     
    },
    iconPosition: ButtonIconPositionShiftLeft,
    align: ButtonAlignCenter,
    flash: ButtonFlashRadial,
    allowPropagate: false,
    loader: {
      fill:"#CCCCCC", 
      wheel: {
        gif:"Spinner-0.4s-310px-ffffff-transparent",
        spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
      }
    }
  }

  export const footerButton = {
    ...greenButton,
    background: {
      active:       "#4c5491",    //#4c5491
      inactive:     "#CCCCCC",
      flash:        "#505dc7",    //#505dc7
      loading:      "#CCCCCC",
      hover:        "#6a72ad",    //#4b449e
      borderRadius: "10px",   
      padding:      "10px"     
    }, 
    iconFromProject:true, 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: "20px"
    }
  }


  export const navMainSearchButton = { 
    ...greenButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb sb-explicit sths-nav-main-search-iconbutton",
    background: {  
      none: true,
      padding:      "10px", 
      borderRadius: "0px"    
    },
    foreground: "#555555", 
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '30px'
    },
    //foreground: "#acdd8c",
    // iconDimension: {
    //   style: ButtonIconActiveHeight,
    //   active: '30px'
    // },
  }

  export const navSearchWidgetButton = { 
    ...greenButton, 
    icon:"rounded-magnifying-glass",
    iconStyle:"transform:rotate(45deg)",
    outerClasses:"sb-explicit sths-nav-main-search-widget-button",
    foreground: "#FFFFFF",
    background: {  
      ...greenButton.background,
      borderRadius: "4px",  
      padding:      "5px",   
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '20px'
    },
  }

  export const prevNextSearchResultsButton = { 
    ...greenButton,
    background: { 
      ...greenButton.background, 
      padding:"7px 20px 7px 20px",
      borderRadius:"3px"
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '25px'  
    } 
  }
  
  // export const roundCloseButton = {
  //   ...greenButton, 
  //   icon:"rounded-close-x_50", 
  //   iconDimension: {
  //     style: ButtonIconActiveHeight,
  //     active: "20px"
  //   },
  //   background: {
  //     ...greenButton.background,
  //     borderRadius: "50%"   
  //   },
  // }

  export const socialMediaButton = {
    foreground: "#163E6F",
    background: {
      active:       "#849CB3",
      inactive:     "#CCCCCC",
      flash:        "#42b6cf", 
      loading:      "#CCCCCC",
      hover:        "#388fa1",
      borderRadius: "50%",   
      padding:      "3px"     
    },
    iconDimension: {
      style: ButtonIconActiveHeight,
      active: '50px'
    },
    iconFromProject: true,
    flash: ButtonFlashRadial,
    allowPropagate: false,
  }


export const baseGalleryButton = {
  foreground: {
    active:   "#FFFFFF",
    inactive: "#AAAAAA",
    flash:    "#FFFFFF",
    loading:  "#FFFFFF" 
  },
  background: { 
    padding:      "10px",
    none: true    
  },
  iconPosition: ButtonIconPositionShiftLeft,
  align: ButtonAlignCenter,
  allowPropagate: false,
  loader: {
    fill:"#CCCCCC", 
    wheel: {
      gif:"Spinner-0.4s-310px-ffffff-transparent",
      spinnerStyle:"width:120%; height:120%; top:-10%; left:-10%; background-size:contain; background-position:center; background-repeat:no-repeat; position:absolute;"
    }
  }
}

export const roundCloseButton = {
  ...baseGalleryButton, 
  icon:"rounded-close-x_50", 
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "20px"
  },
}

export const leftButton = {
  ...baseGalleryButton,
  icon:"rounded-single-chevron-left",
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "40px"
  }
}

export const rightButton = {
  ...baseGalleryButton,
  icon:"rounded-single-chevron-right",
  iconDimension: {
    style: ButtonIconActiveHeight,
    active: "40px"
  }
}









  